var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _vm.page
              ? _c("vue-headful", {
                  attrs: {
                    title: _vm.appName + " - Page Updated: " + _vm.page.title
                  }
                })
              : _c("vue-headful", {
                  attrs: { title: _vm.appName + " - New Page" }
                }),
            _vm.page
              ? _c(
                  "gov-back-link",
                  {
                    attrs: {
                      to: { name: "pages-show", params: { page: _vm.page.id } }
                    }
                  },
                  [_vm._v("Back to page")]
                )
              : _c(
                  "gov-back-link",
                  { attrs: { to: { name: "pages-index" } } },
                  [_vm._v("Back to pages")]
                ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Update request submitted")
                        ]),
                        _c("gov-body", [
                          _vm._v(
                            "\n            Your update request for\n            " +
                              _vm._s(_vm.page ? "this page" : "a new page") +
                              " has been received. It will\n            need to be approved by an admin before the changes will be\n            applied.\n          "
                          )
                        ]),
                        _vm.page
                          ? _c(
                              "gov-button",
                              {
                                attrs: {
                                  to: {
                                    name: "pages-show",
                                    params: { page: _vm.page.id }
                                  }
                                }
                              },
                              [_vm._v("Back to page")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: {
                                  to: {
                                    name: "pages-index"
                                  }
                                }
                              },
                              [_vm._v("Back to pages")]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }